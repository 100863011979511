<script lang="ts">
    import { writable } from 'svelte/store';
    import '../../app.pcss';
    import { onMount, setContext } from 'svelte';
    import { type Session, loadSession } from '$lib/session';
    import Feather from '$lib/components/Feather.svelte';

    const session = writable<Session>({ type: 'loading' });
    setContext('session', session);

    onMount(async () => {
        try {
            const value = await loadSession();
            return session.set(value);
        } catch {
            return session.set({ type: 'guest', failed: true });
        }
    });
</script>

{#if $session.type === 'loading'}
    <div class="flex h-full w-full items-center justify-center">
        <Feather icon="settings" size="48" class="animate-spin text-zinc-900 dark:text-zinc-100" />
    </div>
{:else}
    <slot />
{/if}
